<template>
  <div>
    <div class="p404 bg-cercles">
      <div class="p404__content">
        <img
          class="p404__favicon"
          src="../assets/img/logos/favicon.svg"
          alt=""
        />
        <h2 class="p404__title">{{ $t("pages.seeYouSoonTitle") }}</h2>
        <p>
          {{ $t("pages.seeYouSoonSubtitle1") }} <a class="link" href="mailto:hola@hellobb.net">{{ $t("pages.seeYouSoonSubtitle2") }}</a>. {{ $t("pages.thanksAgain") }}
        </p>
        <!-- <button class="button button--primary button--ico" @click="$router.push({name: 'home'})">
        <span>Vuelve a la página principal</span>
        </button> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SeeYouSoon",
  metaInfo() {
    return {
      title: this.$t("pages.seeYouSoon") + " | HelloBB",
      meta: [
        { name: "description", content: this.$t("pages.weHopeSeeYouSoon") },
        { name: "robots", content: "noindex"},
        { name: 'forceShowPage', content: 'NotFoundPage' }
      ]
    };
  },
};
</script>

<style scoped>
p {
  font-size: 1.2rem;
  line-height: 1.5;
  max-width: 60%; /* O el ancho máximo que prefieras */
  margin: 0 auto; /* Esto centra el contenedor horizontalmente */
}

@media (max-width: 768px) {
  p {
    font-size: 1rem; /* Tamaño de fuente más pequeño para móviles */
    line-height: 1.4; /* Ajuste del interlineado para mejorar la legibilidad en pantallas pequeñas */
    max-width: 90%;
  }
}
</style>